.result-review-container {
  .round__item {
    margin-top: 48px;
    p {
      font-weight: 500;
      line-height: 20px;
    }
    &__content {
      background-color: #00000033;
      border: 1px solid #828282;
      padding: 16px 16px;
      border-radius: 4px;
      margin-top: 16px;
      .attachments,
      .voices {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      label {
        font-weight: 500;
        line-height: 20px;
      }
    }
    .round__item__content {
      .ant-radio-checked .ant-radio-inner::after {
        margin-top: -6.4px !important;
      }
      .notify-cmt-icon span.anticon-message {
        color: rgb(73, 171, 177);
      }
    }
  }

  .ant-upload.ant-upload-select {
    display: block;
    width: 100%;
  }
  .ant-upload-list.ant-upload-list-picture-card {
    display: flex;
    .ant-upload.ant-upload-select {
      display: block;
      width: auto;

      .ant-upload {
        display: inline-block;
      }
    }
  }

  .ant-upload.ant-upload-select-picture-card {
    order: -1;
    background: transparent;
    margin-right: 20px;
    background: transparent;
    border: none;
    text-align: left;

    span.ant-upload {
      display: inline-block;

      .ant-form-item {
        margin: 0;
      }

      .common-btn {
        height: 100px !important;
        width: 135px;
      }
    }
  }
  .ant-carousel {
    .slick-slider.over_4 {
      .file-item {
        padding: 0 10px;
        // width: 145px;
        height: 100px !important;
        position: relative;
        cursor: pointer;
        // width: 135px !important;

        .file-overlay {
          padding: 0 10px;
        }
      }
    }

    .slick-prev,
    .slick-next {
      font-size: 20px;
      color: #828282;
      &:hover {
        display: block !important;
        color: #828282;
      }
    }
    .slick-prev {
      left: -16px;
    }
    .slick-next {
      right: -20px;
    }

    .slick-slider.less_4 {
      .slick-slide {
        width: 135px !important;
        height: 100px !important;
        margin-right: 20px;
        .file-item {
          // padding: 10px;
          &_content {
            width: 135px !important;
          }
        }
      }
    }

    .slick-track {
      display: flex;
    }
    .file-item {
      // width: 145px;
      height: 110px !important;
      position: relative;
      cursor: pointer;
      // width: 135px !important;

      .file-item_content {
        // width: 135px !important;
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        gap: 5px;

        p,
        SPAN {
          line-height: normal;
        }
      }
      &.image {
        .file-item_content {
          // background-color: pink;

          p.fileName {
            color: var(--color-white);
          }
        }
      }

      &.others {
        .file-item_content {
          background-color: #4f4f4f;
        }
      }

      .file-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100% !important;
        width: 100% !important;
        opacity: 0;
        transition: all 0.5s;

        &_content {
          color: white;
          height: 100%;
          background: #247e84;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          // width: 135px !important;
          height: 100px !important;

          span,
          p {
            color: var(--color-white);
          }
        }

        .delete-icon {
          position: absolute;
          top: 8px;
          right: 20px;
          span {
            color: white;
          }
        }
      }

      &:hover {
        .file-overlay {
          opacity: 1;
        }
      }
    }
  }

  .slick-track {
    display: flex;
  }
}
