.ant-picker {
  padding: 12px 10px;
  border-radius: 5px;
  border: 1px solid #828282;
  height: 40px;

  &.ant-picker-disabled {
    .ant-picker-input {
      input[disabled] {
        color: #828282;
      }
    }
    &:hover {
      cursor: default;
      border: none;
      box-shadow: none !important;
    }
  }
  &:hover {
    border: 1px solid #f2f2f2;
    box-shadow: 0px 0px 15px rgba(160, 235, 230, 0.35) !important;
  }
  .ant-picker-suffix {
    pointer-events: unset;
  }
}
.ant-picker.ant-picker-disabled {
  cursor: default !important;
  .anticon[tabindex] {
    cursor: default !important;
  }
}
.ant-picker-input > input[disabled] {
  cursor: default !important;
}
