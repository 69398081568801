.calendar-list__content__Right__content__detail {
  // width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  font-weight: 700;
  justify-content: center;
  background-color: white;
  margin: 7px 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  width: 100%;
  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    padding: 0 12px;
    &.done {
      background-color: rgba(0, 186, 52, 0.1);
      color: rgba(39, 174, 96, 1);
    }
    &.ongoing {
      background-color: rgba(255, 150, 27, 0.1);
      color: rgba(242, 201, 76, 1);
    }
    &.open {
      background-color: rgba(0, 186, 52, 0.1);
      color: rgba(73, 171, 177, 1);
    }
    &.draft {
      background-color: rgba(0, 186, 52, 0.1);
      color: rgba(130, 130, 130, 1);
    }
    .plan-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__status {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 95%;
    height: 30px;
    &.done {
      border: 1px solid rgba(39, 174, 96, 1);
    }
    &.ongoing {
      border: 1px solid rgba(242, 201, 76, 1);
    }
    &.open {
      border: 1px solid rgba(73, 171, 177, 1);
    }
    &.draft {
      border: 1px solid rgba(130, 130, 130, 1);
    }
  }
  &__content {
    width: 95%;
    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 13px;
      font-weight: 800;
      background-color: rgba(224, 224, 224, 1);
      color: rgba(51, 51, 51, 1);
      height: 24px;
      width: 100%;
      text-transform: uppercase;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &__text {
      background-color: rgba(242, 242, 242, 1);
      color: rgba(130, 130, 130, 1);
      padding: 4px;
      font-weight: 400;
      font-size: 12px;
    }
  }
  &__move {
    width: 95%;
    height: 30px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    column-gap: 7px;
    font-size: 12px;
    font-weight: 700;
    border: 1px solid rgba(73, 171, 177, 1);
    color: rgba(73, 171, 177, 1);
    margin-bottom: 7px;
    font-size: 13px;
    &__icon {
      display: flex;
      align-items: center;
      width: 6.56px;
      height: 11.72px;
      span svg path {
        fill: rgba(73, 171, 177, 1);
      }
    }
    cursor: pointer;
  }
}
