.building-info {
  color: #fff;
  width: 160px;
  .building-name {
    font-weight: 700;
  }
  button {
    color: #fff;
    background-color: #247E84;
    border: 1px solid #247E84;
    width: 100%;
    padding: 0px;
    height: 40px;
    margin-top: 16px;
  }
}
.full-screen-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10; 
  width: 40px;
  height: 40px;
  background-color: #fff;
  cursor: pointer;
  padding: 11px;
  border-radius: 2px;
}