.ant-radio-disabled .ant-radio-inner {
  cursor: default !important;
}
.ant-radio-wrapper-disabled {
  cursor: default !important;
}
.ant-radio-disabled {
  cursor: default !important;
}
.ant-radio-disabled + span {
  cursor: default !important;
}
.ant-radio-disabled .ant-radio-input {
  cursor: default !important;
}
.ant-checkbox-disabled {
  cursor: default !important;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: default !important;
}
