.pagination-wrapper {
  display: flex;
  justify-content: center;
  .pagination {
    .ant-pagination-prev {
      width: 30px;
      min-width: 30px;
      height: 30px;
      min-height: 30px;

      & button {
        display: flex;
        justify-content: center;
        background: linear-gradient(0deg, rgba(6, 21, 30, 0.9), rgba(6, 21, 30, 0.9)), #ffffff;
        box-shadow: 0px 6px 16px rgba(41, 43, 50, 0.12);
        border: none;
      }
    }

    .ant-pagination-item {
      width: 30px;
      min-width: 30px;
      height: 30px;
      min-height: 30px;
      background: linear-gradient(0deg, rgba(6, 21, 30, 0.9), rgba(6, 21, 30, 0.9)), #ffffff;
      box-shadow: 0px 6px 16px rgba(41, 43, 50, 0.12);
      border: none;
      & a {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .ant-pagination-item-active {
      background: #49abb1;
      a {
        color: #ffffff;
        font-size: 13px;
        font-weight: 500;
      }
    }

    .ant-pagination-next {
      width: 30px;
      min-width: 30px;
      height: 30px;
      min-height: 30px;

      background: linear-gradient(0deg, rgba(6, 21, 30, 0.9), rgba(6, 21, 30, 0.9)), #ffffff;
      box-shadow: 0px 6px 16px rgba(41, 43, 50, 0.12);

      & button {
        display: flex;
        justify-content: center;

        background: linear-gradient(0deg, rgba(6, 21, 30, 0.9), rgba(6, 21, 30, 0.9)), #ffffff;
        box-shadow: 0px 6px 16px rgba(41, 43, 50, 0.12);
        border: none;
      }
    }

    .ant-pagination-item-ellipsis {
      color: #f2f2f2;
    }

    .ant-pagination-disabled {
      button {
        cursor: default;
      }
    }
  }
}
