.profile {
  .create-user {
    max-width: 1180px;
    margin: auto;
    &__container {
      padding: 0;
    }
    &__form {
      width: 100%;
    }
    .create-user__form__wrapper {
      width: 700px;
      margin: auto;
    }
  }

  &__content {
    margin-left: 15px;
    margin-right: 15px;
    &__title {
      .title-content {
        margin-bottom: 16px !important;
      }
    }
  }
  .ant-upload-select-picture-card {
    .anticon-upload {
      margin-right: -1px;
    }
  }
}

.create-user__form__button {
  display: flex;
  justify-content: center;
  .button-edit {
    button span {
      height: 100%;
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #49abb1;
    }
  }
}
.popup-btn {
  button {
    @media only screen and (max-width: 570px) {
      span:last-child {
        display: none;
      }
    }
    max-width: 104px;
  }
}
.edit-btn {
  button {
    max-width: 104px;
    height: 56px;
    background: #1c282f;
    border: 1px solid #247e84;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #49abb1;
    }
  }
}
.button-wrapper {
  width: 100%;
  display: flex;
  gap: 24px;
  max-width: 100%;
  margin: auto;
  button {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
  }
}
.button-edit {
  width: 100%;
  max-width: 100% !important;
  display: flex;
  gap: 24px;
  justify-content: center;
  .ant-form-item {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #49abb1;
    }
  }
  button {
    display: flex;
  }
}

.popup__form {
  &__item {
    margin-bottom: 64px;
  }
  .button-wrapper {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    width: 100%;
  }
}
.ant-modal-close-x {
  svg {
    width: 19.79px;
    height: 20.38px;
  }
}
.ant-modal-header {
  height: 76px;
  background-color: #222e35;

  .ant-modal-title {
    justify-content: center;
    align-items: center;
    height: 100%;
    display: flex;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #ffffff;
  }
}

.ant-modal-body {
  background-color: #222e35;
  padding: 25px 26px;
}

.ant-select-disabled {
  .ant-select-selector {
    .ant-select-selection-item {
      font-weight: 400;
      line-height: 20px;
      color: #828282;
      display: flex;
      align-items: center;
    }
  }
}

.ant-input-affix-wrapper-disabled {
  .ant-input-disabled,
  .ant-input-prefix {
    font-weight: 400;
    line-height: 20px;
    color: #828282;
    display: flex;
    align-items: center;
  }
}
.create-user__form__item {
  .upload-container {
    height: 100px;
    .ant-form-item-control .ant-upload {
      display: flex;
    }
  }
  .upload-container__item__picture {
  }
  .upload-container__erase {
    margin-top: -30px;
  }
}
.avatar-item {
  display: block !important;
  margin-bottom: 20px;
  .ant-form-item-control .ant-form-item-control-input-content {
    font-size: 13px;
  }
  .create-user__form__input {
    margin-top: -9px;
  }
  .img__wrapper {
    max-height: 150px;
    img {
      width: 100%;
      max-height: 150px;
      object-fit: contain;
      border: 1px solid #828282;
      padding: 5px;
      border-radius: 5px;
    }
  }
}
