.header {
  width: 100%;
  height: 64px;
  background-image: url('../../assets/images/background-1.png');
  background-position: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__background {
    background-color: rgba(68, 125, 115, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &__wrap-left {
    width: 65%;
    height: 100%;

    display: flex;
    align-items: center;
  }

  &__left {
    display: flex;
    height: 58px;
    margin-left: 40px;

    &__icon {
      background-image: url('../../assets/images/logoheader.png');
      background-size: 125px 58px;
      width: 125px;
      height: 58px;
      z-index: 1;
      cursor: pointer;
      width: 120px;
      height: 46px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: center;
      margin-top: 6px;
    }

    &__divider {
      height: 58px;
      width: 1px;
      margin-left: 16px;
      background-color: rgba(189, 189, 189, 1);
    }
  }

  &__center {
    width: 70%;
    height: 100%;

    ul {
      color: white;
      background: transparent;
      height: 100%;
    }

    .ant-menu-horizontal {
      border-bottom: none;
    }
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
    .ant-menu-item:hover::after {
      border-bottom: 4px solid #49abb1 !important;
    }
    .ant-menu-horizontal > .ant-menu-item::after {
      right: 4px;
      left: 4px;
    }
    .ant-menu-title-content {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #ffffff;
      height: 100% !important;
      display: flex !important;
      align-items: center !important;
    }
    .ant-menu-submenu-title {
      height: 100%;
      display: flex;
      align-items: center;
    }
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
      border-bottom: 8px solid #49abb1;
    }

    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after {
      border-bottom: 8px solid #49abb1;
    }

    .ant-menu-horizontal > .ant-menu-item-selected a {
      color: #49abb1;
      font-size: 13px;
      font-weight: bold;
    }

    .anticon {
      color: #ffffff;
    }
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 35%;
    margin-right: 40px;

    &__avatar {
      margin-right: 16px;
      cursor: pointer;
      .ant-avatar {
        width: 100%;
        height: 100%;
        background-color: #ccc;
      }
    }

    &__name {
      cursor: pointer;
      margin-right: 32px;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #ffffff;
      z-index: 2;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__logout {
      span {
        font-weight: 700;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
      }
    }
  }

  &__menu-bar {
    border-bottom: 1px solid #fff;

    & ul {
      background-color: unset;
      background: unset;
      border-right: unset;
    }

    & li:hover {
      background: #fff;
      color: #247e84;
    }
  }

  &__user-block {
    display: block;
    text-align: center;
    padding: 15px 0px;

    & .header__right__name {
      margin: 15px 0px;
    }

    & .header__right__avatar {
      margin: 15px 0px;
    }
  }
  @media (min-width: 768px) {
    & .bars-menu {
      display: none;
    }
  }
  @media (max-width: 767px) {
    .bars-menu {
      display: inline-block;
      float: right;
      height: 46px;
      padding: 6px 6px 12px 6px;
      width: 46px;
      background: none;
      margin-right: 15px;
      &__bars-btn {
        display: inline-block;
        width: 20px;
        height: 2px;
        background: #247e84;
        position: relative;

        &:after,
        &:before {
          content: attr(x);
          width: 20px;
          position: absolute;
          top: -6px;
          left: 0;
          height: 2px;
          background: #247e84;
        }

        &:after {
          top: auto;
          bottom: -6px;
        }
      }
    }

    .left-menu,
    .right-menu {
      display: none;
    }

    .logo a {
      margin-left: -20px;
      padding: 10px 20px;
    }
  }
}

.ant-drawer-content-wrapper {
  max-width: calc(100% - 50px);
  background: #07151ee3;
  .ant-avatar-circle,
  .header__right__name {
    cursor: pointer;
  }
}

.ant-drawer-body {
  padding: 15px;
}

.ant-drawer-content {
  background: #447d7380;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}
.no__avatar .ant-avatar {
  background: none !important;
}
