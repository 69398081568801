.list__filter {
  display: flex;
  justify-content: end;
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #828282 !important;
  }
  .ant-input-affix-wrapper-focused {
    box-shadow: none !important;
  }
  .filter__wrapper {
    width: 372px;
    height: 40px;
    background-color: #222e35;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px;
    border-color: #828282;
    border-radius: 5px;
    .ant-input-suffix {
      cursor: pointer;
      font-size: 20px;
      padding-right: 13px;
      margin-left: 0;
      .anticol-search {
        font-size: 20px;
      }
    }
    input {
      height: 30px;
      border: none;
      width: 308px;
    }
    input:hover {
      box-shadow: none !important;
      border: none !important;
    }
    margin-bottom: 20px;
  }
}
