.edit-building-master {
  padding: 0 20px;
  margin-bottom: 35px;

  &__title {
    .title-content {
      margin-bottom: 16px !important;
    }
  }

  &__form__container {
    max-width: 636px;
    margin: auto;
    background-color: #222e35;

    @media only screen and (max-width: 720px) {
      overflow: scroll;
      overflow-y: hidden;
    }

    &::-webkit-scrollbar {
      width: 5px !important;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px grey;
      border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background: grey;
      border-radius: 10px;
    }
  }

  &__form {
    min-width: 636px;
    display: flex;
    justify-content: center;
    padding: 25px 26px;

    &__wrapper {
      background-color: #222e35;
      width: 584px;

      .edit-building-master__form__item {
        margin-bottom: 8px;

        label {
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: #f2f2f2;
          height: 20px;
          margin-bottom: 8px;
        }

        button {
          margin-top: 15px;
          width: 100%;
          max-width: 584px;
          height: 56px;
          display: flex;
          gap: 10.63px;

          span {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
          }
        }

        .btn-wrapper {
          display: flex;
          gap: 24px;
        }

        .close-icon-container {
          &.first-close {
            margin-top: 32px !important;
          }

          &.not_allow {
            button {
              cursor: not-allowed;
            }
          }

          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(0deg, rgba(14, 29, 37, 0.9), rgba(14, 29, 37, 0.9)), #ffffff;
          box-shadow: 0px 6px 16px rgba(41, 43, 50, 0.12);
          button {
            margin-top: 0 !important;
            height: auto;
          }
          span.anticon-close {
            background: linear-gradient(0deg, rgba(14, 29, 37, 0.9), rgba(14, 29, 37, 0.9)), #ffffff;
            box-shadow: 0px 6px 16px rgba(41, 43, 50, 0.12);
            svg {
              width: 16px;
              height: 16px;
              display: block;
            }
          }
        }

        &.facility__container,
        &.building__container {
          .facility__row,
          .buildingItem__row {
            .ant-select {
              .ant-select-clear {
                margin-right: 24px;
                .anticon-close-circle {
                  font-size: 16px;
                  margin-top: -2px;
                }
              }

              .ant-select-selection-item {
                padding-right: 18px !important;
                margin-top: -8px !important;
                display: block;
                text-overflow: ellipsis;
              }
            }
          }
          .building_selection,
          .user_selection,
          .facility_selection,
          .building_item_selection {
            .ant-select-selection-item {
              padding-right: 18px !important;
              margin-top: -9px !important;
              display: block;
              text-overflow: ellipsis;
            }
            .ant-select-disabled .ant-select-selector .ant-select-selection-item {
              display: inline-block !important;
              margin-top: 9px !important;
            }
            .ant-select-clear {
              margin-right: 24px;
              .anticon-close-circle {
                font-size: 16px;
                margin-top: -2px;
              }
            }
          }

          .addValue_btn {
            margin-top: 0;
            box-shadow: none;
            display: flex;
            align-items: center;
            column-gap: 10px;

            width: 100%;
            background: none;
            cursor: pointer;
            border: none;
            font-weight: bold;
            span {
              font-size: 14px;
              svg {
                display: block;
              }
            }
          }

          .test_btn {
            border: none;
            &:active {
              border: none;
            }
          }
        }
      }
    }
  }

  button.not_allow {
    cursor: not-allowed !important;
  }

  .mini {
    max-height: 644px;
  }

  .ant-input-disabled {
    cursor: default;
  }
}

.popup__form__button {
  margin-top: 16px;
}
.map-full {
  .ant-modal {
    position: unset !important;
  }
  .ant-modal-content {
    position: unset !important;
  }
  &__map-full-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
}
.ant-click-animating-node {
  display: none;
}

[ant-click-animating-without-extra-node='true']::after {
  display: none;
}
