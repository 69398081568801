.master-table {
  .equipment-management__table {
    max-width: 1180px;
    @media only screen and (max-width: 769px) {
      max-width: 1180px;
      overflow: scroll;
      overflow-y: hidden;
    }
    .table-wrap {
      min-width: 670px;
      .ant-space-item {
        padding: 5px;
      }
    }
    .btn-action {
      cursor: pointer;
      width: 32px;
      height: 32px;
      background-color: #222e35;
      box-shadow: 0px 6px 16px rgba(41, 43, 50, 0.12);
      display: flex;
      align-items: center;
      &:hover {
        background: linear-gradient(0deg, rgba(6, 21, 30, 0.9), rgba(6, 21, 30, 0.9));
      }
      span {
        width: 40px;
        display: flex;
        justify-content: center;
      }
    }
    &__container__building {
      padding-left: 20px;
    }
  }
  .no-data {
    overflow: hidden;
    margin: auto;
    max-width: 1180px;
  }
}
