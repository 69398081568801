@import '../../styles/base/responsive';

.rbc-day-slot .rbc-background-event {
  background-color: none;
}

.calendar-list {
  overflow-x: hidden;
  padding: 0 20px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 72px;
    margin-bottom: 50px;

    .calendar-list__title {
      color: white;
    }
    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(0deg, rgba(14, 29, 37, 0.9), rgba(14, 29, 37, 0.9)), #ffffff;
      box-shadow: 0px 6px 16px rgba(41, 43, 50, 0.12);
      color: white !important;
      width: 30px;
      height: 30px;
      cursor: pointer;

      &.active {
        background: #49abb1 !important;
        color: white !important;
        svg {
          path {
            fill: white;
          }
        }
      }
    }
  }

  .title-content {
    @media only screen and (max-width: 1350px) {
      max-width: 1100px;
    }

    &__title {
      max-width: 460px;
    }
  }

  .calendar__wrapper {
    max-width: 1180px;
    margin: 0 auto;

    max-width: 1180px;
    margin-bottom: 20px;

    @media only screen and (max-width: 1350px) {
      max-width: 1100px;
    }

    @media only screen and (max-width: 1065px) {
      overflow-x: scroll;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 5px !important;
        height: 10px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey;
        border-radius: 7px;
      }
      &::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 10px;
      }
    }

    .calendar-list__content {
      min-width: 1000px;
    }
    .main-calendar {
      width: 100%;
      &__title {
        margin-bottom: 16px;
        p {
          color: var(--primary-color);
          font-weight: 700;
          font-size: 18px;
          line-height: 30px;
        }
      }

      &__icon {
        cursor: pointer;
      }
      .rbc-row.rbc-month-header {
        background-color: var(--primary-color);
        .rbc-header {
          padding: 6px;
          text-align: left;
          text-transform: uppercase;
          font-size: 13px;
          text-align: center;
        }
      }
      .rbc-month-row {
        .rbc-row-bg {
          .rbc-day-bg {
            cursor: pointer;
          }
          .rbc-off-range-bg {
            background-color: #4c5861 !important;
          }
        }
        .rbc-row-content {
          .rbc-button-link {
            color: #969696;
            font-weight: 500;
            font-size: 16px;
          }

          .rbc-row {
            cursor: pointer;
            .rbc-date-cell {
              padding: 0 8px;
            }
            .rbc-row-segment {
              .rbc-background-event {
                background-color: none !important;
              }
              .rbc-show-more {
                color: white;
                background-color: transparent;
              }
              .rbc-event {
                background: none !important;
                &:focus {
                  outline: none !important;
                }
                .rbc-event-content {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  .event {
                    font-weight: 500;
                    font-size: 11px;
                    text-align: center;
                    border-radius: 2px;
                    padding: 0 4px;

                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    text-align: left;

                    &.done {
                      color: #27ae60;
                      background: linear-gradient(0deg, rgba(0, 186, 52, 0.1), rgba(0, 186, 52, 0.1)), #ffffff;
                    }
                    &.ongoing {
                      color: #f2c94c;
                      background: linear-gradient(0deg, rgba(242, 201, 76, 0.1), rgba(242, 201, 76, 0.1)), #ffffff;
                    }
                    &.open {
                      background: #cfeafa;
                      color: #49abb1;
                    }
                    &.draft {
                      background-color: #f2f2f2;
                      color: #828282;
                    }
                  }
                }
              }
              .rbc-show-more {
                margin-left: 15px;
                font-size: 13px;
                .show-more-btn {
                }
              }
            }
          }
        }
      }
    }
  }
  .ant-spin-spinning {
    color: #247e84;
    top: 50% !important;
    width: 100%;
    padding-top: 5px;
    text-shadow: none;
    max-width: 100vw;
    @media only screen and (max-width: 1350px) {
      max-width: 1100px;
    }
  }
  .calendar__wrapper {
    .right-calendar-spin {
      top: 200px !important;
    }
  }
}

.ant-popover-content {
  .ant-popover-title {
    color: #08979c;
  }

  .ant-popover-inner-content {
    max-height: 500px;
    overflow-y: scroll;
  }

  .more-plans {
    .event {
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      border-radius: 2px;
      padding: 0 4px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      width: 100%;
      height: 23px;
      line-height: 23px;
      text-align: left;
      cursor: pointer;

      margin-bottom: 6px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;

      &.done {
        color: #27ae60;
        background: linear-gradient(0deg, rgba(0, 186, 52, 0.1), rgba(0, 186, 52, 0.1)), #ffffff;
      }
      &.ongoing {
        color: #f2c94c;
        background: linear-gradient(0deg, rgba(242, 201, 76, 0.1), rgba(242, 201, 76, 0.1)), #ffffff;
      }
      &.open {
        background: #cfeafa;
        color: #49abb1;
      }
      &.draft {
        background-color: #f2f2f2;
        color: #828282;
      }
    }
  }
}
