.main {
  &__content {
    min-height: 100vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
    flex-grow: 1;
    position: relative;
  }

  &__component {
    padding-bottom: 50px;
  }

  &__footer {
    width: 100%;
    text-align: center;
    height: 50px;
    position: absolute;
    bottom: 0px;
  }
}
