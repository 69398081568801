.notify-cmt-icon {
  position: relative;
  display: flex;
  margin-right: 8px;
  span.anticon-message {
    color: white;
  }
  .red-dot {
    display: block;
    width: 10px !important;
    height: 10px !important;
    border-radius: 50%;
    background-color: red;
    position: absolute;
    right: -7px;
    top: -3px;
  }
}
