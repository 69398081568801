.app {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  height: 100%;
  width: 100%;
  min-width: 320px;
  overflow-x: auto;
}

.ant-form-item-control-input {
  min-height: unset !important;
}
