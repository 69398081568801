.login {
  width: 100%;

  &__header {
    width: 100%;
    height: 322px; //370px

    &__background-first {
      width: 100%;
      height: 94px;
      background: rgba(68, 125, 115, 0.5);
    }

    &__background-second {
      width: 100%;
      border-left: 49vw solid transparent;
      border-right: 49vw solid transparent;
      border-top: 65px solid rgba(68, 125, 115, 0.5);
    }

    &__image-logo {
      text-align: center;
      margin: 0 auto;
      position: absolute;
      top: 96px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  h1 {
    font-weight: 700;
    line-height: 60px;
    font-size: 32px;

    text-align: center;
    text-transform: uppercase;

    color: #f2f2f2;
    margin-top: 50px;
    margin-bottom: 32px;
  }

  .form-block {
    max-width: 560px;
    background: linear-gradient(0deg, rgba(126, 126, 126, 0.25), rgba(126, 126, 126, 0.25)), rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 40px rgba(24, 35, 43, 0.1);
    backdrop-filter: blur(600px);
    margin: 0 auto;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__row-input {
      padding-bottom: 5px;
    }

    &__row-button {
      gap: 18px;
      padding-top: 10px;
    }

    .ant-form-item-label > label {
      font-weight: 500;
    }

    .ant-input-affix-wrapper {
      padding: 0px;
      height: unset;
      cursor: default;
    }

    .ant-form-item {
      line-height: 20px;

      .ant-form-item-required::before {
        display: none;
      }

      .ant-btn {
        font-weight: 700;
        line-height: 20px;
        width: 100%;
        max-height: 72px;
      }
    }
  }
  .ant-form-item-explain-error {
    font-size: 13px;
  }
}
.ant-btn-loading-icon svg {
  color: #fff;
}
