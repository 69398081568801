.default-select,
.typing-select {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  .ant-select-selector {
    height: 40px !important;
    border-radius: 4px !important;
    border: 0.5px solid #828282 !important;
    background: #4242421a !important;
  }
}
.disable-select {
  .ant-select-selector {
    background-color: #384044 !important;
    border: 1px solid #828282 !important;
    border-radius: 4px !important;
  }
}
.error-select {
  .ant-select-selector {
    background: #4242421a !important;
    border: 1px solid #eb5757 !important;
    border-radius: 4px !important;
    box-shadow: 0px 0px 15px rgba(232, 78, 78, 0.35) !important;
  }
}
.typing-select:hover {
  .ant-select-selector {
    background: #4242421a !important;
    border: 1px solid #f2f2f2 !important;
    border-radius: 4px !important;
    box-shadow: 0px 0px 15px rgba(160, 235, 230, 0.35) !important;
  }
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: default !important;
}
.ant-select-selector {
  cursor: pointer !important;
}
.ant-select-selection-item,
.ant-select-selection-placeholder {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 40px;
}
input {
  height: 40px;
}
.ant-select-item-option-disabled {
  color: #828282;
  cursor: default;
}

.ant-select-item {
  &.ant-select-item-option-selected {
    color: #247e84;
  }
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: default !important;
}
