.required-label {
  background-color: #d35561;
  border-radius: 4px;
  margin-left: 10px;
  span {
    color: #ffffff;
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    font-size: 12px;
    text-align: center;
    line-height: 22px;
    height: 22px;
  }
}
