.img-wrapper {
  width: 50px;
  height: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: #247e84;
  border-radius: 5px 0px 0px 5px !important;
  border: 1px solid #828282;
  border-right: unset;
  color: #fff;

  & span {
    color: #fff;
    font-size: 20px;
  }
}
