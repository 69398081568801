.title-content {
  margin-top: 60px !important;
  margin-bottom: 20px !important;
  max-width: 1180px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: default;
  &__icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg, rgba(6, 21, 30, 0.9), rgba(6, 21, 30, 0.9)), #ffffff;
    box-shadow: 0px 6px 16px rgba(41, 43, 50, 0.12);
    cursor: pointer;
  }

  &__back {
    justify-content: flex-start;
    width: 30px;
    height: 30px;
    align-items: center;

    svg {
      font-size: 13px;
      fill: rgba(181, 173, 176, 1);
    }
  }

  &__action {
    justify-content: flex-end;
    display: flex;
  }
}
