.-container {
  font-size: 13px;
  display: flex;
  .ant-upload-picture-card-wrapper {
    width: auto !important;
  }
  .-section {
    &.no-file {
      flex: 1;
    }

    .common-btn {
      height: 56px !important;
      width: 100%;
    }
    .ant-upload.ant-upload-select {
      display: block;
      width: 100%;
    }

    .ant-upload.ant-upload-select-picture-card {
      order: -1;
      background: transparent;
      border: none;
      margin-right: 20px;
      background: transparent;
      text-align: left;

      span.ant-upload {
        display: inline-block;

        .ant-form-item {
          margin: 0;
        }

        .common-btn {
          height: 100px !important;
          width: 135px;
          @media only screen and (max-width: 860px) {
            width: 150px;
          }
        }
      }
    }
  }

  .ant-carousel {
    // width: 620px;
    width: calc(100% - 200px);
    @media only screen and (max-width: 860px) {
      width: calc(100% - 170px);
    }
  }

  .upload-small-btn {
    display: flex;
    flex-direction: column;
    span {
      font-size: 16px;
    }
  }
}

p.delete-all {
  font-weight: 500;
  line-height: 20px;
  color: #eb5757;
  position: absolute;
  right: 0;
  top: -25px;

  cursor: pointer;
}
