.popup__form {
  max-width: 814px;
  &__wrapper {
    .popup__form__input {
      .popup__form__item {
        margin-bottom: 12px;
      }
    }
    .popup__form__button {
      .button-wrapper {
        display: flex;
        gap: 24px;
        width: 100%;
        padding-top: 15px;
      }

      button {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
      }
    }
  }
  label {
    height: 20px;
    line-height: 20px;
    font-style: normal;
    font-weight: 500;
    color: #ffffff;
  }
  .ant-form-item-label {
    margin-bottom: 12px;
  }
}
