.status {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 13px 0;
  font-size: 13px;
  font-weight: 600;

  &__text {
    font-weight: 600;
    line-height: 20px;
  }

  &.calendar {
    .status__icon {
      width: 12px;
      height: 12px;
      border-radius: 25px;
      margin-right: 8px;
    }
    .status__text {
      font-size: 13px;
    }
  }
  &.plan {
    .status__icon {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 12px;
    }
  }
  &.done {
    .status__icon {
      background-color: rgba(39, 174, 96, 1);
    }
    .status__text {
      color: rgba(39, 174, 96, 1);
    }
  }
  &.ongoing {
    .status__icon {
      background-color: rgba(242, 201, 76, 1);
    }
    .status__text {
      color: rgba(242, 201, 76, 1);
    }
  }
  &.open {
    .status__icon {
      background-color: rgba(73, 171, 177, 1);
    }
    .status__text {
      color: rgba(73, 171, 177, 1);
    }
  }
  &.draft {
    .status__icon {
      background-color: rgba(130, 130, 130, 1);
    }
    .status__text {
      color: rgba(130, 130, 130, 1);
    }
  }
  &.cancel {
    .status__icon {
      background-color: #ff4d4f;
    }
    .status__text {
      color: #ff4d4f;
    }
  }
}
