.table-wrap {
  width: 100%;
  height: 100%;

  & .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #222e35 !important;
  }

  & .ant-table {
    background: transparent;
    font-style: normal;
    line-height: 20px;
    .ant-table-container {
      table {
        border-spacing: 0 10px;
      }
    }
  }

  & .ant-table-thead {
    & th {
      color: #49abb1;
    }

    & tr > th {
      border-bottom: none;
      background: transparent;
      font-weight: 700;
      cursor: default;
    }

    & tr > th::before {
      position: relative !important;
    }

    & .ant-table-cell > span {
      margin-right: 10.13px;
    }
  }

  & .ant-table-tbody {
    color: #f2f2f2;
    font-weight: 500;

    & tr {
      background: #222e35;
      box-shadow: 0px 0px 40px rgba(24, 35, 43, 0.1);
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
    }

    & tr:hover {
      background: #222e35;
      box-shadow: 0px 0px 40px rgba(24, 35, 43, 0.1);
    }

    & .ant-table-row-selected > td {
      background: #222e35;
      box-shadow: 0px 0px 40px rgba(24, 35, 43, 0.1);
    }

    & tr > td {
      border-bottom: solid 1px #06151eb8;
      padding: 2px;
      height: 40px;
      box-sizing: border-box;
    }

    & .ant-checkbox-input {
      padding: 20px;
      width: 30px;
      height: 30px;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #49abb1;
    border-color: #49abb1;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .ant-table-tbody tr:last-child > td {
    border-bottom: none;
  }
  .ant-table-placeholder .ant-table-cell {
    background-color: #222e35 !important;
  }
  .ant-empty-description {
    color: #ffffff;
  }
}
.ant-tooltip-inner {
  font-size: 13px;
}
