@import '../../styles/base/responsive';

.equipment-management {
  padding: 0 20px;

  .title-content {
    margin-bottom: 0px !important;
  }

  &__container {
    @include Responsive(XXL) {
      max-width: 1500px;
    }
    margin: auto;
  }

  &__title {
    .holder__icon {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(0deg, rgba(6, 21, 30, 0.9), rgba(6, 21, 30, 0.9)), #ffffff;
      box-shadow: 0px 6px 16px rgba(41, 43, 50, 0.12);
      cursor: pointer;
    }
    .anticon-form {
      svg {
        font-size: 40px;
      }
    }
    svg {
      font-size: 13px;
    }

    .buttons {
      display: flex;
      column-gap: 8px;
      justify-content: flex-end;

      button {
        display: flex;
        background: #247e84;
      }

      .cancel-button {
        background-color: #247e84;
        border: none;
        color: rgba(242, 242, 242, 1);
      }

      .delete-button {
        background-color: rgba(27, 38, 45, 0.7);
        border: 1px solid #247e84;
        color: #247e84;
      }

      span {
        width: 69px;
        height: 20px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  &__table {
    .ant-input {
      border: none !important;
    }
    @media only screen and (max-width: 570px) {
      max-width: 570px;
      overflow: scroll;
      overflow-y: hidden;
    }
    max-width: 1180px;
    margin: auto;
    width: 100%;
    &::-webkit-scrollbar {
      width: 5px !important;
      height: 10px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px grey;
      border-radius: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background: grey;
      border-radius: 10px;
    }
    &__title {
      display: flex;
      &__wrapper {
        display: flex;
        padding-left: 20px;
      }
      &__sort {
        justify-items: center;
        align-items: flex-start;
        margin-top: -1px;
        width: 20px;
        display: flex;
        flex-direction: column;
        .anticon-caret-up,
        .anticon-caret-down {
          height: 9px;
        }
      }
    }

    &__container {
      display: flex;
      padding-left: 20px;
      .check {
        &active {
          background-color: #49abb1;
          border-radius: 50%;
          margin-left: -12px;
          width: 20px;
          height: 20px;
          color: #49abb1;
          margin-right: 12px;
        }

        &disable {
          background-color: #828282;
          border-radius: 50%;
          margin-left: -12px;
          width: 20px;
          height: 20px;
          color: #49abb1;
          margin-right: 12px;
        }
      }

      .active {
        color: #49abb1;
      }

      .disable {
        color: #828282;
      }

      .ant-table-row {
        padding: 0;
      }
    }
    .ant-table-wrapper {
      min-width: 570px;
      .ant-table-tbody tr td {
        background-color: #222e35 !important;
      }
    }
    .btn-action {
      cursor: pointer;
      width: 32px;
      height: 32px;
      background-color: #222e35;
      box-shadow: 0px 6px 16px rgba(41, 43, 50, 0.12);
      display: flex;
      align-items: center;
      span {
        width: 40px;
        display: flex;
        justify-content: center;
      }
      &:hover {
        background: linear-gradient(0deg, rgba(6, 21, 30, 0.9), rgba(6, 21, 30, 0.9));
      }
    }
    .action {
      padding-right: 13px;
      .ant-space-item {
        padding: 5px;
      }
    }

    .ant-checkbox-inner {
      width: 30px;
      height: 30px;
      border: 1px solid rgba(130, 130, 130, 1);
      background: linear-gradient(0deg, rgba(86, 86, 86, 0.25), rgba(86, 86, 86, 0.25)),
        linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    }

    .ant-checkbox-inner::after {
      width: 10px;
      height: 18px;
    }
  }
  .no-data {
    overflow: hidden;
    margin: auto;
    max-width: 1180px;
  }
  &__pagination {
    margin-top: 22px;
  }
  .ant-table-cell {
    padding-left: 0;
  }
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  color: #247e84;
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: none;
}
.ant-spin-spinning {
  color: #247e84;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: none;
}
.ant-modal-body {
  min-height: 290px;
}
