@import '../../../styles/base/responsive';

.history-management {
  padding: 0 10px;

  &__container {
    margin: auto;
    padding: 0 20px;
    padding-bottom: 20px;

    .title-content {
      margin-top: 60px !important;
      margin-bottom: 0 !important;
    }
  }

  tr > td {
    padding: 0;
  }
  tr > td:first-child {
    padding-left: 16px;
  }

  .ant-table-cell:nth-child(3),
  .ant-table-cell:nth-child(4) {
    padding-right: 16px;
  }

  &__table {
    height: 100%;
    margin: auto;
    width: 100%;
    max-width: 1180px;

    .column__title {
      display: flex;
      .anticon-caret-down,
      .anticon-caret-up {
        padding-right: 20px;
        align-items: center;
      }
      &__wrapper {
        display: flex;
        width: 100%;
        span {
          width: 100%;
        }
      }
      .dropdown__status {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      &__sort {
        margin-top: -1px;
        display: flex;
        width: 20px;
        flex-direction: column;
        .anticon-caret-up,
        .anticon-caret-down {
          height: 9px;
        }
        .anticon-filter {
          display: flex;
          align-items: center;
          height: 100%;
          width: 20px;
        }
      }
    }

    .table-wrap .ant-table-tbody {
      tr td {
        background-color: #222e35 !important;
        padding: 0px 4px;

        .text {
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
        }
      }
      .planName {
        padding-left: 20px;
      }
    }
    & .ant-table-tbody > tr.ant-table-row:hover > td {
      background: #222e35 !important;
    }

    @media only screen and (max-width: 1225px) {
      overflow-x: scroll;
      overflow-y: hidden;
      &::-webkit-scrollbar {
        width: 5px !important;
        height: 10px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey;
        border-radius: 7px;
      }
      &::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 10px;
      }
    }

    .table-wrap {
      min-width: 1180px;
    }

    &__title {
      padding-left: 40px;
    }

    &__container {
      display: flex;

      .text {
        align-items: center;
        display: flex;
        margin-left: 24px;
      }

      .active {
        color: #49abb1;
      }

      .disable {
        color: #828282;
      }
    }

    .ant-table-thead .ant-table-cell {
      padding: 16px 0;
    }
    .ant-table-cell {
      .status {
        justify-content: flex-start !important;
      }
      .btn-action {
        cursor: pointer;
        width: 32px;
        height: 32px;
        background-color: #222e35;
        box-shadow: 0px 6px 16px rgba(41, 43, 50, 0.12);
        display: flex;
        align-items: center;
        span {
          width: 40px;
          display: flex;
          justify-content: center;
        }
        &:hover {
          background: linear-gradient(0deg, rgba(6, 21, 30, 0.9), rgba(6, 21, 30, 0.9));
        }
      }
      .action {
        padding-right: 13px;
      }
    }
  }

  &__pagination {
    margin-top: 32px;
  }
}
.ant-dropdown-menu {
  background-color: #222e35;
}
.ant-dropdown-menu-item-selected {
  background-color: #247e84;
}
.menu__item {
  color: white;
  height: 34px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.ant-dropdown-menu-item:hover {
  background-color: #247e84;
}
