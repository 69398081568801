.plan__action__container {
  background-position: center;
  background-size: cover;
  .a {
    height: calc(100% - 118px);
  }
  .plan__action {
    display: flex;
    justify-content: center;
    gap: 30px;
    cursor: pointer;
    .icon__add,
    .icon__list {
      display: flex;
      justify-content: center;
    }
    .box {
      &__map {
        @media (max-width: 992px) {
          justify-content: center;
        }
        justify-content: flex-start;
      }
    }
  }
}
