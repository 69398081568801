.ant-carousel {
  .carousel-component {
    .slick-slider.over_than_quantity {
      .file-item {
        width: auto !important;
      }
    }
    .carousel-component.less_than_quantity .slick-slide .file-item {
      .file-item {
        width: auto !important;
      }
    }
  }
  .ant-upload.ant-upload-select {
    display: block;
    width: 100%;
  }
  .ant-upload-list.ant-upload-list-picture-card {
    display: flex;
    .ant-upload.ant-upload-select {
      display: block;
      width: auto;

      .ant-upload {
        display: inline-block;
      }
    }
  }

  .ant-upload.ant-upload-select-picture-card {
    order: -1;
    background: transparent;
    border: none;
    margin-right: 20px;
    background: transparent;
    border: none;
    text-align: left;

    span.ant-upload {
      display: inline-block;

      .ant-form-item {
        margin: 0;
      }

      .common-btn {
        height: 100px !important;
        width: 135px;
      }
    }
  }

  .slick-slider.over_than_quantity {
    .file-item {
      width: auto !important;
    }
  }

  .slick-prev,
  .slick-next {
    font-size: 20px;
    color: #828282;
    display: block !important;
    &:hover {
      display: block !important;
      color: #828282;
    }
  }
  .slick-prev {
    left: -16px;
  }
  .slick-next {
    right: -20px;
  }

  .slick-track {
    display: flex;
  }
  .file-item {
    // width: 145px;
    height: 110px !important;
    position: relative;
    cursor: pointer;
    // width: 135px !important;

    .file-item_content {
      // width: 135px !important;
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      gap: 5px;

      span.upload-icon {
        svg {
          width: 16px;
          height: 16px;
        }
      }

      p,
      SPAN {
        line-height: normal;
      }

      .file-name-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 8px;
        p.file-name {
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-size: 16px;
        }
      }
    }
    &.image {
      .file-item_content {
        p.fileName {
          color: var(--color-white);
        }
      }
    }

    &.others {
      .file-item_content {
        background-color: #4f4f4f;
      }
    }

    .file-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100% !important;
      width: 100% !important;
      opacity: 0;
      transition: all 0.5s;

      &_content {
        color: white;
        height: 100%;
        background: #247e84;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0px !important;
        height: 100px !important;

        span,
        p {
          color: var(--color-white);
        }
      }

      .delete-icon {
        position: absolute;
        top: 8px;
        right: 20px;
        z-index: 10;
        span {
          color: white;
        }
      }
    }

    &:hover {
      .file-overlay {
        opacity: 1;
      }
    }
  }

  .slick-track {
    display: flex;
  }

  .slick-slider.less_than_quantity {
    .file-item {
      width: auto !important;
    }
  }
}

.less_than_quantity .slick-slide .file-item {
  width: auto;
}
