.profile-admin {
  .create-user__form {
    width: 700px !important;
    &__wrapper {
      width: 100%;
      .create-user__form__item {
        width: 100%;
      }
    }
    .edit-col {
      display: flex;
      padding-bottom: 20px;
      flex-direction: row;
      padding-left: 12px;
      padding-right: 12px;
      flex-direction: column;
      @media only screen and (max-width: 575px) {
        flex-direction: row;
      }
    }
    .avatar {
      padding-bottom: 0;
    }
    .popup-btn {
      button {
        @media only screen and (max-width: 450px) {
          span:last-child {
            display: none;
          }
        }
        max-width: 104px;
      }
    }
  }
}
