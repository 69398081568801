.calendar-list__content__Right {
  background-color: rgba(130, 130, 130, 1);
  height: 100%;
  margin-top: 45px;
  &__title {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    background-color: rgba(73, 171, 177, 1);
    &__previous {
      width: 9.84px;
      height: 17.58px;
      span svg path {
        fill: white;
      }
      cursor: pointer;
    }
    &__middle {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      min-width: 60%;
      &__day {
        font-size: 32px;
        font-weight: 700;
      }
      &__month {
        font-size: 16px;
        font-weight: 700;
        display: flex;
        flex-direction: column;
      }
    }
    &__next {
      width: 9.84px;
      height: 17.58px;
      span svg path {
        fill: white;
      }
      cursor: pointer;
    }
  }

  &__content {
    &__inside {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    height: 641px;
    padding: 10px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
  }
  .ant-spin-nested-loading > div > .ant-spin {
    top: 220px !important;
  }
}
