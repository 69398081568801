.create-admin {
  .create-user__form {
    .create-user__form__item .holder__phone__wrapper {
      .ant-select-selector {
        display: flex;
        align-items: center;
        .ant-select-selection-placeholder {
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
}
