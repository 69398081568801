.input-field {
  color: #f2f2f2;

  input {
    padding: 12px 10px;
    border-radius: 4px;
    border: 0.5px solid #828282;
    background: #4242421a;
    font-size: 13px;

    &:focus,
    &:hover {
      border: 1px solid #f2f2f2;
      box-shadow: 0px 0px 15px rgba(160, 235, 230, 0.35) !important;
    }
  }

  .ant-input-disabled {
    cursor: default;
    border-radius: 4px;
    border: 0.5px solid #828282;
    background-color: #384044 !important;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #828282;

    &:hover {
      box-shadow: none !important;
      border: 0.5px solid #828282;
    }

    input {
      height: 40px;
    }
  }

  .ant-input-group-addon {
    background: #4242421a;
    border-color: #828282;
    cursor: pointer;
  }
}