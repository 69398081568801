.bonus-checkbox-form {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;

  .ant-row.ant-form-item {
    flex-grow: 1;
    margin: 0;
  }

  .ant-checkbox-wrapper {
    .ant-checkbox-inner {
      width: 25px;
      height: 25px;
      border: 1px solid #828282;
      background: linear-gradient(0deg, rgba(86, 86, 86, 0.25), rgba(86, 86, 86, 0.25)),
        linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
      &::after {
        width: 9px;
        height: 15px;
        transform: rotate(45deg) scale(1) translate(-57%, -60%);
      }
    }

    .ant-checkbox-checked {
      & .ant-checkbox-inner {
        background-color: #49abb1;
        border-color: #49abb1;
      }

      &.ant-checkbox-disabled .ant-checkbox-inner::after {
        border-color: var(--second-color);
      }
    }
  }

  .close-icon-container {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg, rgba(14, 29, 37, 0.9), rgba(14, 29, 37, 0.9)), #ffffff;
    box-shadow: 0px 6px 16px rgba(41, 43, 50, 0.12);
    span.anticon-close {
      background: linear-gradient(0deg, rgba(14, 29, 37, 0.9), rgba(14, 29, 37, 0.9)), #ffffff;
      box-shadow: 0px 6px 16px rgba(41, 43, 50, 0.12);
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
.addValueBtn {
  &:hover {
    background-color: none !important;
    border: none !important;
  }
}

.action-btn-groups {
}
