.create-master {
  &__container {
    &__item {
      label {
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: #f2f2f2;
        height: 20px;
        margin-bottom: 12px;
      }
    }
  }
  .btn-wrapper {
    margin-top: 48px;
    button {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
    }
  }
}
