.password-field {
  border-radius: 4px;
  .ant-input-password {
    padding: 0;
    height: 40px;
    display: inline-flex;
    padding: 0;
    input {
      padding: 12px 10px;
      height: 40px;
      width: 100%;
    }
    .ant-input-suffix {
      display: grid;
      margin-left: -25px;
      z-index: 1;
    }
  }
  .ant-select-selector {
    border: none !important;
  }
  &input:focus {
    border: 1px solid #f2f2f2 !important;
    box-shadow: 0px 0px 15px rgba(160, 235, 230, 0.35) !important;
    border-color: #f2f2f2 !important;
  }
  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    border-color: #ff4d4f !important;
  }
}
