@import '../../styles/base/responsive';

.not-found-page {
  text-align: center;
  height: calc(100vh - 50px);
  display: flex;
  &__wraper {
    margin: auto;
  }
  &__number {
    font-weight: 900;
    color: #fff;
    font-size: 100px;
    margin-bottom: -10px;
    @include Responsive(XS) {
      font-size: 150px;
      margin-bottom: -10px;
    }
    @include Responsive(SM) {
      font-size: 200px;
      margin-bottom: -30px;
    }
    @include Responsive(MD) {
      font-size: 250px;
      margin-bottom: -60px;
    }
    @include Responsive(LG) {
      margin-bottom: -90px;
      font-size: 300px;
    }
  }

  &__title {
    width: 100%;
    color: #fff;
    font-size: 35px;
    @include Responsive(XS) {
      font-size: 20;
    }
    @include Responsive(SM) {
      font-size: 30;
    }
    @include Responsive(MD) {
      font-size: 55;
    }
    @include Responsive(LG) {
      font-size: 70;
    }
  }

  &__content {
    width: 100%;
    color: #fff;
    font-size: 13px;
    @include Responsive(XS) {
      font-size: 13px;
    }
    @include Responsive(SM) {
    }
    @include Responsive(MD) {
      font-size: 24px;
    }
    @include Responsive(LG) {
      font-size: 28px;
    }
  }
  &__button {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
