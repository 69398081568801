@import '../../../styles/base/responsive';

.title-page {
  h1 {
    padding-top: 60px;
    padding-bottom: 60px;
    font-style: normal;
    font-weight: bold;
    line-height: 43px;
    text-align: center;
    text-transform: uppercase;
    color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    @include Responsive(XS) {
      font-size: 25px;
      height: 40px;
    }
    @include Responsive(SM) {
      font-size: 28px;
      height: 40px;
    }
    @include Responsive(MD) {
      font-size: 30px;
      height: 43px;
    }
    @include Responsive(LG) {
      font-size: 30px;
      height: 43px;
    }
  }
}
