.analysis-container {
  display: flex;
  justify-content: center;
  padding: 0 20px;
  &__wrapper {
    max-width: 1180px;
    width: 100%;
    display: flex;
    justify-content: center;

    .analysis-content-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 48px;
      .analysis-content__plan {
        background: #222e35;
        padding-bottom: 44px;

        &__title {
          padding: 0px 10px 0px 0px;
          margin-bottom: 48px;
          h1 {
            font-size: 26px;
          }
          &__icon {
            cursor: pointer;
            justify-content: center;
            width: 30px;
            height: 30px;
            align-items: center;
            display: flex;
            background: linear-gradient(0deg, rgba(14, 29, 37, 0.85), rgba(14, 29, 37, 0.85)), #ffffff;
            box-shadow: 0px 6px 16px rgba(41, 43, 50, 0.12);
            svg {
              fill: rgba(181, 173, 176, 1);
              width: 17.53px;
              height: 16.5px;
            }
            &__disabled {
              cursor: not-allowed;
              justify-content: center;
              width: 30px;
              height: 30px;
              align-items: center;
              display: flex;
              background: linear-gradient(0deg, rgba(14, 29, 37, 0.85), rgba(14, 29, 37, 0.85)), #ffffff;
              box-shadow: 0px 6px 16px rgba(41, 43, 50, 0.12);
            }
          }

          .title-content {
            margin-top: 0px !important;
            margin-bottom: 0px !important;
          }
        }
        &__filter {
          width: 100%;
          margin: 0 !important;
          padding: 0 5px;
          padding-bottom: 48px;
          &__item {
            &:last-child {
              display: flex;
              align-items: end;
            }
            .ant-select-selector {
              background: #222e35 !important;
            }
          }
        }
        .filter-doughnut {
          justify-content: flex-end;
        }
        &__bar-chart {
          display: flex;
          justify-content: center;
          min-height: 200px;
          .ant-spin-spinning {
            color: #247e84;
            position: absolute;
            top: 50%;
            width: 100%;
            padding-top: 5px;
            text-shadow: none;
          }
          &__wrapper {
            max-width: 1180px;
            width: 100%;
            height: 396px;
            padding: 0 10px;
            display: flex !important;
            justify-content: center;
          }
          .no__data {
            height: 396px;
            display: flex !important;
            justify-content: center;
          }
        }
        &__pie-chart {
          min-height: 200px;
          .ant-spin-spinning {
            color: #247e84;
            position: absolute;
            top: 50%;
            width: 100%;
            padding-top: 5px;
            text-shadow: none;
          }
          &__wrapper {
            max-width: 1180px;
            width: 100%;
            min-height: 396px;
            padding: 0 10px;
          }
        }
        &:last-child {
          margin-bottom: 20px;
        }
        .ant-form-item-with-help .ant-form-item-explain {
          height: 20px;
        }
      }
    }
    .ant-picker {
      width: 302px;
      background: #222e35;
      border: 1px solid #828282;
      border-radius: 4px;
      font-weight: 400;
      line-height: 20px;
      color: #828282;
      .ant-picker-input input {
        // color: #828282;
      }
      .ant-picker-suffix span {
        color: #f2f2f2;
      }
    }

    .ant-form-item-label > label {
      height: 0;
      padding-bottom: 24px;
    }
  }
}
.analysis-title {
  padding: 0 20px;
}
.ant-picker-body {
  background-color: #06151e;
}
.ant-picker-cell-in-view:hover {
  .ant-picker-cell-inner {
    background-color: #247e84 !important;
  }
}
.ant-picker-panel {
  border: none;
}
.ant-empty-description {
  color: #ffffff;
}
.ant-select-selector {
  box-shadow: none !important;
  border-right-width: 0px !important;
}
.ant-picker {
  box-shadow: none !important;
}
.ant-select-item-option-content {
  display: flex;
  justify-content: space-between;
}
.option-wrap {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  padding-right: 15px;
  align-items: center;
  &__name {
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 5px;
  }
  &__active {
    background: #d35561;
    border-radius: 4px;
    color: #fff;
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    font-size: 12px;
    text-align: center;
    line-height: 22px;
    height: 22px;
  }
}
.legend__wrap {
  margin-top: 30px;
  padding: 0 10px;
  max-height: 396px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px !important;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }
}
#legend-id {
  width: 100%;
  width: 100%;
  float: right;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  div {
    display: inline-flex;
    margin: 6px;
    justify-content: center;
    gap: 10px;
    margin-bottom: 8px;
    float: left;
    cursor: pointer;
    span {
      width: 50px;
      height: 20px;
    }
  }
}
