.bonus-radio-form {
  display: flex;
  // align-items: center;
  gap: 12px;
  margin-bottom: 16px;

  .ant-row.ant-form-item {
    flex-grow: 1;
    margin: 0;
  }

  .close-icon-container {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg, rgba(14, 29, 37, 0.9), rgba(14, 29, 37, 0.9)), #ffffff;
    box-shadow: 0px 6px 16px rgba(41, 43, 50, 0.12);
    span.anticon-close {
      background: linear-gradient(0deg, rgba(14, 29, 37, 0.9), rgba(14, 29, 37, 0.9)), #ffffff;
      box-shadow: 0px 6px 16px rgba(41, 43, 50, 0.12);
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.addValueBtn {
  display: flex;
  align-items: center;
  column-gap: 10px;

  width: 100%;
  background: none;
  cursor: pointer;
  border: none;
  font-weight: bold;
}
