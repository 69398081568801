.building-map-master {
  padding: 0 50px;
  margin-bottom: 35px;

  &__container {
    &__filter {
      max-width: 1000px;
      margin: auto;
      margin-bottom: 16px;
      &__selector {
        width: 100%;
        margin-top: 6px;
      }
      .ant-select-clear {
        margin-right: 20px;
      }
    }
    &__map {
      max-width: 1000px;
      margin: auto;
      position: relative;
    }
    &__map-full {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 3;
    }
  }

  &__search {
    margin-bottom: 10px;
  }

  .ant-input{
    border: 0.5px solid #828282 !important;
  }
}

.ant-select-selector {
  border-right-width: 1px !important;
  border: 0.5px solid #828282 !important;
}
.ant-input-search-button {
  border: 0.5px solid #828282 !important;
  height: 40px;
  background: linear-gradient(0deg, rgba(6, 21, 30, 0.9), rgba(6, 21, 30, 0.9)), #ffffff;
}
.ant-col {
  font-weight: 700;
}
.label-select {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__active {
    background: #d35561;
    border-radius: 4px;
    color: #fff;
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    font-size: 12px;
    text-align: center;
    line-height: 22px;
    height: 22px;
    margin-right: 15px;
  }
}