.ant-drawer-header {
  .ant-drawer-title {
    margin-top: 16px;
    .comment-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 20px;
    }
  }
}

.plan_comment {
  .comment-textarea {
    background: #f2f2f2;
    color: #247e84;
  }
  .add-cmt-btn {
    margin: 0;
    width: 100%;
  }
}

.comment-list {
  .ant-input[disabled] {
    color: #247e84 !important;
    padding: 4px 8px;
  }
  .comment-content-container {
    color: #247e84 !important;

    .comment-btn-container {
      display: flex;
      justify-content: right;
      .ant-row.ant-form-item {
        width: auto !important;
        button.cmt-more-btn {
          color: #247e84;
          text-align: right;
          display: flex;
        }
      }
    }
  }
  .ant-divider {
    margin: 12px 0 !important;
    background-color: #828282 !important;
  }
  .comment-text {
    white-space: pre-line;
  }
}

.ant-drawer-content-wrapper {
  max-width: 100vw !important;
}

.ant-drawer-body {
  max-width: 1300px;
  margin: 0 auto;
  width: 100%;
}

.ant-drawer-footer {
  max-width: 1300px;
  margin: 0 auto;
  width: 100%;
}

.ant-spin-spinning {
  left: 0;
}

.ant-input[disabled] {
  cursor: default;
  border-radius: 4px;
  border: 0.5px solid #828282;
  background-color: #384044 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #828282;
}
