.ant-picker {
  cursor: pointer;
}
.ant-picker.ant-picker-disabled {
  background-color: #384044 !important;
  border: 1px solid #828282 !important;
  border-radius: 4px !important;
  color: #828282;
  .anticon[tabindex] {
    cursor: default !important;
  }
}

body {
  .ant-picker-panel-container {
    .ant-picker-ok {
      button {
        cursor: pointer;
        color: black;
      }

      button[disabled] {
        cursor: unset;
        &:hover {
          opacity: 1 !important;
          color: black;
        }
      }
    }
  }
}
