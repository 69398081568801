.popup-container {
  .ant-modal-header {
    background: #f2f2f2;
    height: 60px;
    border-bottom: 1px solid #bdbdbd;
    .ant-modal-title {
      color: #333333;
      font-size: 28px;
    }
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background: #f2f2f2;
    padding: 25px 63px 32px 63px;

    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      padding-bottom: 9px;
      text-align: center;
      color: #4f4f4f;
    }
    .anticon-exclamation-circle {
      font-size: 65px;
      color: #eb5757;
    }
  }
  .ant-form-item-control-input-content {
    display: flex;
    width: 100%;
    justify-content: center;

    .ant-btn {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
    }
  }
}
