@import './responsive';

* {
  font-family: NotoSansJP, Inter;
}

// with px
@for $i from 1 through 100 {
  .w-#{$i} {
    width: $i + 0%;
  }
  .w-#{$i}-i {
    width: $i + 0% !important;
  }
}
@include Responsive(LG) {
  @for $i from 1 through 100 {
    .w-lg-#{$i} {
      width: $i + 0%;
    }
    .w-lg-#{$i}-i {
      width: $i + 0% !important;
    }
  }
}
@include Responsive(XL) {
  @for $i from 1 through 100 {
    .w-xl-#{$i} {
      width: $i + 0%;
    }
    .w-xl#{$i}-i {
      width: $i + 0% !important;
    }
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
}

a {
  color: var(--color-blue-5);
}
