.create-master {
  padding: 0 20px;
  &__title {
    .title-content {
      margin-bottom: 16px !important;
    }
  }
  &__container {
    .create-master__form__wrapper {
      max-width: 636px;
      margin: auto;
      background-color: #222e35;
      @media only screen and (max-width: 720px) {
        overflow: scroll;
        overflow-y: hidden;
      }
      &::-webkit-scrollbar {
        width: 5px !important;
        height: 10px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey;
        border-radius: 7px;
      }
      &::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 10px;
      }
    }
    .create-master__form {
      min-width: 636px;
      display: flex;
      justify-content: center;
      form {
        background-color: #222e35;
        width: 636px;
        padding: 25px 26px;
      }

      &__item {
        label {
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: #f2f2f2;
        }
        textarea {
          height: 128px;
        }
        .ant-form-item-label {
          margin-bottom: 8px;
          .ant-form-item-no-colon {
            color: #e0e0e0;
            width: 189px;
            height: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
          }
        }
        .upload-container {
          margin-bottom: 0;
          .ant-form-item-control {
            margin-bottom: -25px;
          }
        }
        .input-field {
          margin-bottom: 8px;
        }
      }
    }
  }
}
