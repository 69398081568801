$XS: 480px;
$SM: 540px;
$MD: 720px;
$LG: 960px;
$XL: 1280px;

@mixin Responsive($canvas) {
  @if $canvas == XS {
    @media only screen and (min-width: $XS) {
      @content;
    }
  } @else if $canvas == SM {
    @media only screen and (min-width: $SM) {
      @content;
    }
  } @else if $canvas == MD {
    @media only screen and (min-width: $MD) {
      @content;
    }
  } @else if $canvas == LG {
    @media only screen and (min-width: $LG) {
      @content;
    }
  } @else if $canvas == XL {
    @media only screen and (min-width: $XL) {
      @content;
    }
  }
}
