.user__action__container {
  background-position: center;
  background-size: cover;
  .a {
    height: calc(100% - 118px);
  }
  .title-content__title {
    h1 {
      font-style: normal;
      font-weight: 700;
      line-height: 60px;
      text-align: center;
      color: #e0e0e0;
      font-size: 30px;
    }
  }
  .user__action {
    padding: 0 10px;
    width: 100%;
    .ant-card {
      cursor: pointer;
    }
    @media (min-width: 1129px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 0 10px;
      &__item {
        .box {
          .card-holder__title {
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 39px;
            color: #333333;
            text-align: center;
            padding: 0;
          }
        }
      }
    }

    @media (max-width: 1128px) {
      &__item {
        margin: 0 !important;
        width: 100%;
        .box {
          display: flex;
          &__add {
            @media (max-width: 992px) {
              justify-content: center;
            }
            justify-content: flex-end;
          }
          &__list {
            @media (max-width: 992px) {
              justify-content: center;
            }
            justify-content: flex-start;
          }
          .card-holder__title {
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 39px;
            color: #333333;
            text-align: center;
            padding: 0;
          }
        }
      }
    }
  }
}
