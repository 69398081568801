.review__plan {
  .plan__title {
    h1 {
      padding-bottom: 20px;
    }
  }
  .download__plan__icon {
    cursor: pointer;
    justify-content: center;
    width: 30px;
    height: 30px;
    align-items: center;
    display: flex;
    background: linear-gradient(0deg, rgba(14, 29, 37, 0.85), rgba(14, 29, 37, 0.85)), #ffffff;
    box-shadow: 0px 6px 16px rgba(41, 43, 50, 0.12);
    svg {
      fill: rgba(181, 173, 176, 1);
      width: 17.53px;
      height: 16.5px;
    }
  }

  &__main {
    margin: auto;
    .building_selection,
    .user_selection,
    .facility_selection,
    .building_item_selection {
      .ant-select-selection-item {
        padding-right: 18px;
        margin-top: -1px;
        display: block;
        text-overflow: ellipsis;
      }
    }

    // width: 1300px;
    label {
      font-weight: 500;
      line-height: 20px;
    }
    &__content {
      max-width: 1180px;
      margin: 0 auto;
    }

    &__check {
      .ant-tabs.tabs-container {
        .ant-tabs-nav::before {
          border-color: transparent !important;
        }

        .ant-tabs-tab {
          /* For tabs border */
          border-color: transparent;
        }

        .ant-tabs-nav {
          .ant-tabs-nav-wrap {
            display: block !important;

            .ant-tabs-nav-list {
              .ant-tabs-tab {
                flex: 1;
                display: block !important;

                .ant-tabs-tab-btn {
                  text-align: center;

                  p.check-tab {
                    font-family: Inter;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 20px;
                    letter-spacing: 0em;
                    color: #828282;
                    font-weight: 700;
                    line-height: 20px;
                  }
                }

                &.ant-tabs-tab-active {
                  p.check-tab {
                    color: var(--primary-color);
                  }
                }
              }
            }
          }
        }

        .ant-tabs-ink-bar {
          height: 3px;
          background: var(--primary-color) !important;
        }
      }
    }
  }
}
