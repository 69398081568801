@import '../../styles/base/responsive';

.create-user {
  &__title {
    .title-content {
      margin-bottom: 16px !important;
    }
  }
  &__container {
    width: 100%;
    padding: 0 20px;
  }

  &__form {
    max-width: 700px;
    margin: auto;
    display: flex;
    justify-content: center;
    .ant-form-item-label {
      padding: 0;
    }
    &__button {
      margin-top: 10px;
    }
    &__wrapper {
      background-color: #222e35 !important;
      gap: 48px;
      width: 100%;
      .create-user__form__input {
        row-gap: 0px;
        &_left,
        &__right {
          width: 100%;
        }
      }

      box-shadow: 0px 0px 40px rgba(24, 35, 43, 0.1);
      padding: 25px 26px;

      .ant-form-item-label {
        margin-bottom: 8px;

        label {
          color: #e0e0e0;
          width: 189px;
          height: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
        }
      }
      .holder {
        &__phone {
          .ant-input-group {
            display: flex;
          }
          .ant-form-item-control .ant-select-selector {
            background-color: #247e84 !important;
          }
          .ant-input-affix-wrapper {
            border-radius: 4px;
            border: 0.5px solid #828282;
            background: #4242421a;
            input {
              border: none;
            }
          }
          .ant-input-affix-wrapper-status-success {
            border: 1px solid #f2f2f2;
            box-shadow: 0px 0px 15px rgba(160, 235, 230, 0.35) !important;
            border-color: #f2f2f2;

            input {
              border: none;
            }
          }
          .input-field__phone .error-message {
            background: #222e35;
          }
        }

        &__email {
          @include Responsive(XS) {
            width: calc(50% - 7.5px);
          }
          @include Responsive(LG) {
            width: 50%;
          }
          padding-right: 0;
        }
      }
    }
    &__item {
      width: 100%;
      margin-bottom: 8px;
      flex-direction: column;
      .ant-form-item {
        display: flex;
        flex-direction: column;

        .ant-form-item-label {
          text-align: start;
        }
      }
      &__phone {
        .error-message {
          margin-left: -42px;
        }
      }
    }

    .input-phone {
      width: 56px;
      text-align: center;
      background-color: #8686864d;
      height: 100%;
      border-radius: 4px;
      cursor: unset;
      .ant-select-arrow {
        display: none;
      }
      .ant-select-selector {
        display: flex;
        background: rgba(134, 134, 134, 0.3) !important;
        border: 0.5px solid #828282;
        border-right: none;
        border-radius: 4px 0 0 4px !important;
      }
      .ant-select-arrow {
        font-size: 21.5px;
      }
      .img-wrapper {
        img {
          width: 30px;
        }
      }
    }
    .input-field__phone {
      width: 100%;
      margin-left: 1px;
      height: 100%;

      &__error-message {
        margin-left: -72px;
      }
      .ant-form-item-control-input-content {
        input {
          border-radius: 0px 4px 4px 0px !important;
        }
      }
      .ant-input-affix-wrapper {
        border-radius: 0px 4px 4px 0px;
      }
    }
  }
  .ant-select-selector {
    display: flex;
    background: #222e35;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    width: 30px;
    display: flex;
    align-items: center;
    &:hover {
      border-color: #ffffff;
    }
    .img-wrapper {
      display: flex;
    }
  }
  .ant-select-item-option-content {
    .img-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 30px;
      }
    }
  }
  .anticon-phone {
    font-size: 30px;
    color: #ffffff;
  }
}
.ant-form-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0;

  .ant-form-item-label {
    text-align: start;
  }
}

.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: unset;
}
.ant-form-item-label > label {
  font-size: 13px;
}
.ant-form-item-with-help .ant-form-item-explain {
  min-height: 23px !important;
}
