.group-column-to-row {
  background-color: #00000033;
  border: 1px solid #828282;
  padding: 10px 0px;
  border-radius: 4px;
  width: 100%;
  // height: 110px;
}

.ant-select-arrow {
  font-size: 15px;
}

.radio {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid white;
}

.ant-upload ant-upload-select ant-upload-select-text {
  width: 100%;
}

.ant-btn ant-btn-default {
  width: 100%;
}

.form-session {
  background: linear-gradient(0deg, rgba(126, 126, 126, 0.25), rgba(126, 126, 126, 0.25)), rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 40px rgba(24, 35, 43, 0.1);
  backdrop-filter: blur(600px);
  padding: 56px 40px;
  margin-bottom: 48px;

  .title-content {
    padding: 0;
    margin: 0 !important;
    h1 {
      padding: 0;
      margin: 0 !important;
    }
  }

  h1.form-title {
    font-size: 25px;
  }

  .ant-radio-wrapper {
    // align-items: center;

    .ant-radio {
      .ant-radio-inner {
        width: 20px;
        height: 20px;
        background: transparent;
        border: 0.5px solid #828282;
      }
    }

    .ant-radio-checked .ant-radio-inner::after {
      width: 12px;
      height: 12px;
      transform: scale(1);
      margin-top: -5.4px;
      margin-left: -5.9px;
    }

    &.ant-radio-wrapper-checked {
      span:nth-child(2) {
        top: -84x;
      }
    }
    &.editting {
      .ant-radio-checked .ant-radio-inner::after {
        margin-top: -5.4px !important;
      }
    }
  }

  .job-list-container {
    height: 100%;
    display: inline-block;
    overflow: hidden;
    .job-list-content {
      max-height: 1095px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .group-checklist {
    padding-right: 16px;
    padding-left: 8px;
    .job {
      display: flex;
      align-items: center;
      justify-content: space-between;

      label.ant-radio-wrapper {
        margin-top: -5px;
        display: flex;
        align-items: center;
        flex-grow: 1;

        span:nth-child(2) {
          flex-grow: 1;
          margin-top: 0;
          &::after {
            margin-top: -5.4px;
          }
        }
      }

      &__radio__content {
        padding: 4px 0px;
        margin-top: 5px;
        font-weight: 400;
        text-align: left;
        width: 150px;
        @media only screen and (max-width: 1100px) {
          width: 65px;
        }
        @media only screen and (max-width: 768px) {
          width: 200px;
        }
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__radio__right {
        display: flex;
        flex-direction: row;
        gap: 20px;
      }

      .radio-normal {
        font-size: large;
        display: block;
        color: red;
      }

      .radio-hide {
        // display: none;
        &:hover {
          color: red;
        }
      }

      &:hover .radio-hide {
        font-size: large;
        display: block;
      }
    }
  }

  .addValueBtn {
    background: none;
    cursor: pointer;
  }

  .radio-normal {
    font-size: large;
    display: block;
    color: red;
  }

  .more__job {
    display: flex;
    text-align: left;
    padding-left: 8px;
    margin-top: -10px;
    .ant-btn > .anticon + span,
    .ant-btn > span + .anticon {
      margin-left: -3px;
    }

    .addValueBtn {
      display: flex;
      align-items: center;
      column-gap: 10px;
      padding: 0;
      justify-content: left;
      width: 100%;
      background: none;
      cursor: pointer;
      border: none;
      font-weight: bold;
    }

    &__content {
      align-items: center;

      &____left {
      }

      &__middle {
      }

      &__right {
        display: flex !important;
        gap: 10px;
      }
    }
  }

  .checklist-detail {
  }

  .report__sub__text {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    margin-top: -40px;
    margin-bottom: -20px;
    &__right {
      display: flex;
      align-items: center;
    }
    p {
      margin-left: 0px;
      font-family: Inter;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      font-weight: 500;
      color: #f2f2f2;
    }

    button {
      margin-right: 0px;

      span {
        svg {
          height: 18.75px;
          width: 18.75px;
          left: 2.625px;
          top: 2.625px;
          border-radius: 0px;
        }
      }
    }
  }

  .reportType-container {
    background-color: #00000033;
    border: 1px solid #828282;
    padding: 10px 0px;
    border-radius: 4px;
    margin-top: -16px;
  }

  .addValueBtn {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 0;

    width: 100%;
    background: none;
    cursor: pointer;
    border: none;
    font-weight: bold;
  }

  &__content {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    column-gap: 5px;
    align-items: center;

    &____left {
    }

    &__middle {
    }

    &__right {
      display: flex !important;
      gap: 20px;

      .reportType-container {
        background-color: #00000033;
        border: 1px solid #828282;
        padding: 10px 0px;
        border-radius: 4px;

        // .report-number__type {
        //   .ant-input-number-disabled {
        //     background: #828282;
        //     color: white;
        //   }
        // }
      }
    }

    .upload-section {
      width: 100%;
      display: block;

      .ant-upload.ant-upload-select {
        display: block;
      }

      .anticon-upload {
        color: var(--primary-color);
      }
    }
  }

  .tabs-container {
    .forms-container {
    }

    .result-container {
    }
  }

  button.ant-tabs-nav-more {
    display: none;
  }

  .ant-spin-container::after {
    width: calc(100% + 30px);
    height: calc(100% + 30px);
    margin: -15px;
    background: #253036;
  }
}
