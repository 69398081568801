.ant-carousel {
  padding: 0 10px;
  .ant-upload.ant-upload-select {
    display: block;
    width: 100%;
  }
  .ant-upload-list.ant-upload-list-picture-card {
    display: flex;
    .ant-upload.ant-upload-select {
      display: block;
      width: 100%;

      .ant-upload {
        display: inline-block;
      }
    }
  }

  .ant-upload.ant-upload-select-picture-card {
    order: -1;
    background: transparent;
    border: none;
    margin-right: 20px;
    background: transparent;
    text-align: left;

    span.ant-upload {
      display: inline-block;

      .ant-form-item {
        margin: 0;
      }

      .common-btn {
        height: 100px !important;
        width: 135px;
      }
    }
  }

  .slick-slider.over_than_quantity {
    .file-item {
      padding: 0 10px;
      height: 100px !important;
      position: relative;
      cursor: pointer;
      .file-overlay {
        padding: 0 10px;
      }
    }
  }

  .slick-slider.less_than_quantity {
    .slick-slide {
      width: 135px !important;
      height: 100px !important;
      margin-right: 20px;
      .file-item {
        &_content {
          width: 135px !important;
        }
      }
    }
  }

  .slick-prev,
  .slick-next {
    font-size: 20px;
    color: #828282;
    &:hover {
      display: block !important;
      color: #828282;
    }
  }
  .slick-prev {
    left: -16px;
  }
  .slick-next {
    right: -20px;
  }

  .slick-slider.less_4 {
    .slick-slide {
      width: 135px !important;
      height: 100px !important;
      margin-right: 20px;
      .file-item {
        &_content {
          width: 135px !important;
        }
      }
    }
  }

  .slick-track {
    display: flex;
  }
  .file-item {
    height: 110px !important;
    position: relative;
    cursor: pointer;

    .file-item_content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 19.75px;
      .anticon-file-image {
        font-size: 16px;
      }
      p,
      span {
        line-height: normal;
      }
    }
    &.image {
      .file-item_content {
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        p.fileName {
          color: var(--color-white);
        }
        .file__content {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0;
          &__top {
            display: block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding-left: 8px;
          }
          &__bottom {
            padding-right: 8px;
          }
        }
      }
    }

    &.others {
      .file-item_content {
        background-color: #4f4f4f;
        .file__content {
          width: 100%;
          display: flex;
          gap: 0;
          align-items: center;
          justify-content: center;
          &__top {
            display: block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding-left: 8px;
          }
          &__bottom {
            padding-right: 8px;
          }
        }
      }
    }

    .file-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100% !important;
      width: 100% !important;
      opacity: 0;
      transition: all 0.5s;

      &_content {
        color: white;
        height: 100%;
        background: #247e84;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 19.75px;
        height: 100px !important;
        .anticon-download {
          font-size: 16px;
        }
        span,
        p {
          color: var(--color-white);
        }
      }

      .delete-icon {
        position: absolute;
        top: 8px;
        right: 20px;
        span {
          color: white;
        }
      }
    }

    &:hover {
      .file-overlay {
        opacity: 1;
      }
      p,
      span {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
      }
    }
  }

  .slick-track {
    display: flex;
  }
}
.loading-carousel {
  height: 100px;
  .ant-spin-spinning {
    top: 35%;
  }
}
.slick-disabled {
  cursor: default !important;
}

.ant-carousel {
  .slick-next:focus,
  .slick-prev:focus {
    color: #828282;
  }
}
