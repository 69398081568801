.card-holder {
  display: flex;
  justify-content: center;
  &__icon {
    display: flex;
    justify-content: center;
    width: 80px;
    height: 80px;
    padding: 0;
    text-align: center;
    width: 100%;
  }

  &__title {
    padding-top: 24px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 39px;
    color: #333333;
  }
  &__description {
    margin-top: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #828282;
  }
  .ant-card-body {
    justify-items: center;
    display: flex;
    flex-direction: column;
    padding: 32px 0;
    cursor: pointer;
  }

  .icon-container {
    span {
      display: block;
      svg {
        width: 100%;
        height: 100%;
        display: block;
        color: #49abb1;
      }
    }
  }
}
