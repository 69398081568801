.popup-container {
  .ant-modal-header {
    background: #f2f2f2;
    border-bottom: 1px solid #bdbdbd;
    height: 60px;
    .ant-modal-title {
      color: #333333;
      font-size: 28px;
    }
  }
  .ant-modal-body {
    min-height: 0;
    // height: 150px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background: #f2f2f2;
    padding: 35px;
    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 30px;
      padding: 0px;
      text-align: center;
      color: #4f4f4f;
    }
    .button__wrap {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }
  .ant-form-item-control-input-content {
    display: flex;
    width: 100%;
    justify-content: center;
    .ant-btn {
      font-weight: 700;
      display: flex;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
    }
  }
}
