.plan__main {
  margin: auto;
  padding: 0 20px;
  max-width: 1300px;
  overflow: hidden;

  margin-bottom: 48px;

  .form-title {
    font-size: 25px;
  }

  label {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
  }

  .plan__review__main__check {
    margin-top: 48px;
  }

  .normal-user {
    @media only screen and (max-width: 760px) {
      margin-bottom: 20px;
    }
  }

  .period-user {
    margin-bottom: 10px;
    @media only screen and (max-width: 760px) {
      margin-bottom: 40px;
    }
  }

  .plan__validation__mess {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
  }

  .ant-form-item-label {
    label {
      font-size: 13px;
    }
  }

  .plan-main-form-wrapper {
    margin-bottom: 48px;
    .plan-main-form {
      .plan__review__main__content {
        max-width: 1180px;
        margin: 0 auto;
      }
      .plan__review__main__check {
        max-width: 1180px;
        margin: 0 auto;
        margin-top: 48px;
      }
    }
  }

  .form-session {
    margin-bottom: 0;
  }
  .plan-action-btn {
    max-width: 1180px;
    margin: 0 auto;
  }

  .plan__review__main__check {
    .plan-spin {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.plan__title {
  padding: 0 20px;
  .title-content {
    margin-bottom: 16px !important;
  }
}

.common-btn {
  span {
    font-weight: 700;
    font-size: 16px;
  }

  font-weight: bold;
  color: var(--primary-color);
  display: flex;
}

.border-error {
  border-color: #eb5757 !important;
}

.border-solid {
  border-bottom: 1px solid;
  height: 60px;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.padding-lr-none {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
