@import '../../styles/base/responsive';

.user-management {
  padding: 0 20px;
  width: 100%;
  height: 100%;
  padding-bottom: 19px;
  .title-content {
    margin-bottom: 0px !important;
  }
  &__background {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  &__content {
    max-width: 1180px;
    height: 100%;
    margin: auto;

    &__title {
      margin: auto auto 0px auto;
    }
    &__name {
      display: flex;
      .checkbox {
        margin-left: -46px;
        margin-right: 16px;
      }
    }
    &__status {
      display: flex;
      .check {
        &active {
          background-color: #27ae60;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          color: #27ae60;
          margin-right: 12px;
        }

        &inactive {
          background-color: #feed5a;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          color: #feed5a;
          margin-right: 12px;
        }
      }

      .active {
        color: #27ae60;
      }

      .inactive {
        color: #feed5a;
      }
    }
    &__main {
      .ant-input {
        border: none !important;
      }
      @media only screen and (max-width: 1219px) {
        max-width: 1180px;
        overflow: scroll;
        overflow-y: hidden;
      }
      margin: auto;
      width: 100%;
      max-width: 1180px;
      &::-webkit-scrollbar {
        width: 5px !important;
        height: 10px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey;
        border-radius: 7px;
      }
      &::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 10px;
      }
      .ant-table-cell:first-child {
        padding-left: 20px;
      }
      .ant-table-cell {
        padding-left: 0;
      }

      &__sort {
        margin-top: -1px;
        width: 20px;
        display: flex;
        flex-direction: column;
        .anticon-caret-up,
        .anticon-caret-down {
          height: 9px;
        }
      }

      &__title {
        padding-left: 20px;
        width: 40px;
      }
      &__title__wrapper {
        display: flex;
        .anticon-caret-down,
        .anticon-caret-up {
          padding-right: 20px;
          align-items: center;
        }
      }
      .table-wrap {
        min-width: 1180px;
        .btn-action {
          cursor: pointer;
          width: 32px;
          height: 32px;
          background-color: #222e35;
          box-shadow: 0px 6px 16px rgba(41, 43, 50, 0.12);
          display: flex;
          align-items: center;
          span {
            width: 40px;
            display: flex;
            justify-content: center;
          }
          &:hover {
            background: linear-gradient(0deg, rgba(6, 21, 30, 0.9), rgba(6, 21, 30, 0.9));
          }
        }
        .action {
          padding-right: 13px;
          .ant-space-item {
            padding: 5px;
          }
        }
        .ant-table-tbody tr td {
          background-color: #222e35 !important;
        }
      }
    }

    &__pagination {
      margin-top: 32px;
    }
  }

  &__buttons {
    display: flex;
    column-gap: 8px;
    justify-content: flex-end;

    button {
      display: flex;
      padding: 18px 37.5px;
      max-width: 144px;
      height: 56px;
      @include Responsive(XS) {
        width: 48px;
        height: 26px;
      }
      @include Responsive(SM) {
        width: 54px;
        height: 36px;
      }
      @include Responsive(MD) {
        width: 72px;
        height: 42px;
      }
      @include Responsive(LG) {
        width: 96px;
        height: 50px;
      }
      @include Responsive(XL) {
        padding: 18px 37.5px;
        width: 144px;
        height: 56px;
      }
    }
    .ant-btn-background-ghost {
      span {
        letter-spacing: -2px;
      }
    }
  }
  .ant-table-thead .ant-table-cell {
    padding: 16px 0;

    .anticon-mail,
    .anticon-phone,
    .anticon-user {
      color: #49abb1;
    }
  }
  .ant-checkbox-inner {
    width: 30px;
    height: 30px;
    border: 1px solid rgba(130, 130, 130, 1);
    background: linear-gradient(0deg, rgba(86, 86, 86, 0.25), rgba(86, 86, 86, 0.25)),
      linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  }

  .ant-checkbox-inner::after {
    width: 10px;
    height: 18px;
  }

  .status-column {
    display: flex;
    gap: 10px;
    span {
      display: flex;
      align-items: center;
    }
    svg {
      width: 14px;
      height: 14px;
    }
  }
}
.ant-notification-notice-description {
  color: #000;
  font-size: 12px;
}
.ant-table-cell {
  cursor: pointer;
}
