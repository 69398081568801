.upload-container {
  width: 100%;
  height: 100%;
  color: #000;
  display: flex;
  flex-direction: column;
  @media (max-width: 575px) {
    flex-direction: row;
  }
  &__erase {
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #eb5757;
    margin-top: -52px;
    display: flex;
    justify-content: end;
    margin-bottom: 12px;
    span {
      cursor: pointer;
    }
  }
  &__item {
    &__picture {
      height: 99px;
      display: flex;
      .ant-upload-picture-card-wrapper {
        width: 120px;
      }

      .ant-carousel {
        width: calc(100% - 200px);
        height: 99px;
        margin-right: 19px;
        flex: 1;
        margin-left: 30px;
        .slick-prev,
        .slick-next {
          font-size: 20px;
          color: #828282;
        }
        .slick-track {
          display: flex;
          gap: 12px;
        }
        .slick-slide {
          width: 120px !important;
          &:first-child {
            margin-left: -5px;
          }
        }
        .anticon-close {
          font-size: 15px;
          color: #ffffff;
        }
        .slick-list .slick-slide {
          pointer-events: unset;
        }
      }
      span {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        color: #49abb1;
      }
      .ant-upload {
        width: 100%;
        height: 99px;
        background-color: #1c282f;
        display: flex;
        border: none;
        cursor: pointer;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          flex-direction: column;
          color: #49abb1;
        }
        .anticon-upload {
          border-top: none;
          border-bottom: none;
        }
        span {
          width: 120px;
          background-color: #1c282f;
          border: 1px solid #247e84;
        }
      }
      .ant-upload-list {
        display: flex;
        flex-direction: row-reverse;
      }
      .img-list {
        &__item {
          width: 120px;
          height: 99px;
          a {
            background-color: #49abb1;
          }
          &__content {
            height: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position-x: center;
            background-position-y: center;
            display: flex;
            justify-content: center;
            .close__wrapper {
              padding-top: 10px;
              padding-right: 10px;
              .anticon-close {
                display: flex;
                justify-content: flex-end;
              }
            }
            .file__content {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: -25px;
              flex-direction: column;
              &__name {
                display: flex;
                width: -webkit-fill-available;
                padding: 0 10px;
                justify-content: center;
              }
              &__icon {
                svg {
                  width: 16px;
                  height: 16px;
                }
              }
              p {
                display: inline;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }
          }
        }
      }
      .ant-carousel .slick-list .slick-slide > div > div {
        display: flex !important;
        gap: 12px;
      }
    }
    &__text {
      .ant-upload-select-text {
        height: 56px;
        width: 100%;
        span {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          color: #49abb1;
        }
        .ant-upload {
          width: 100%;
          height: 56px;
          display: flex;
          justify-content: center;
          background-color: #1c282f;
          border: 1px solid #247e84;
          cursor: pointer;
          div {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 11.23px;
            span {
              border: none;
              width: 20px;
            }
          }
        }
      }
    }
  }
  .single-picture {
    .anticon-upload {
      border-top: none;
      border-bottom: none;
      margin-left: -1px;
    }
    .img-list__item__content {
      display: block !important;
    }
  }
}
.ant-form-item-control-input-content input {
  font-size: 13px !important;
}
.ant-notification-notice-message {
  font-size: 13px !important;
}
