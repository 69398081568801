.create-user__form__button {
  display: flex;
  justify-content: center;
}
.edit-btn {
  display: flex;
  justify-content: end;

  button {
    max-width: 104px;
    height: 56px;
    background: #1c282f;
    border: 1px solid #247e84;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #49abb1;
    }
  }
}
.button-wrapper {
  width: 100%;
  max-width: 760px;
  display: flex;
  gap: 16px;

  button {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
  }
}
.button-edit {
  max-width: 476px;
  width: 100%;
  display: flex;
  justify-content: center;
  .ant-form-item {
    max-width: 476px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #49abb1;
    }
  }
}
.popup__form {
  &__item {
    margin-bottom: 64px;
  }
  .button-wrapper {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    width: 100%;
  }
}
.ant-modal-close-x {
  svg {
    width: 19.79px;
    height: 20.38px;
  }
}
.ant-modal-header {
  height: 76px;
  background-color: #222e35;

  .ant-modal-title {
    justify-content: center;
    align-items: center;
    height: 100%;
    display: flex;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #ffffff;
  }
}

.ant-modal-body {
  background-color: #222e35;
  padding: 32px 24px;
}

.ant-select-disabled {
  .ant-select-selector {
    .ant-select-selection-item {
      font-weight: 400;
      line-height: 20px;
      color: #828282;
      display: flex;
      align-items: center;
    }
  }
}

.ant-input-affix-wrapper-disabled {
  .ant-input-disabled,
  .ant-input-prefix {
    font-weight: 400;
    line-height: 20px;
    color: #828282;
    display: flex;
    align-items: center;
  }
}
