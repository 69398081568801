.master__action__container {
  background-position: center;
  background-size: cover;
  @media (max-width: 1340px) {
    padding-bottom: 35px;
  }
  .master__action__item {
    padding: 0 10px;
    width: 100%;
    .ant-card {
      cursor: pointer;
    }
    @media (min-width: 1341px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 0 10px;
      &__box {
        .box {
          .card-holder__title {
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 39px;
            color: #333333;
            text-align: center;
            padding: 0;
          }
        }
      }
    }
    @media (max-width: 1340px) {
      &__box {
        margin: 0 !important;
        width: 100%;
        .box {
          display: flex;
          justify-content: center;
          .card-holder__title {
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 39px;
            color: #333333;
            text-align: center;
            padding: 0;
          }
        }
      }
    }
  }
}
