@import '../../styles/base/responsive';

.plan-management {
  &__container {
    margin: auto;
    padding: 0 20px;
    padding-bottom: 20px;
  }

  &__title {
    .title-content {
      margin-bottom: 0px !important;

      @media only screen and (max-width: 1350px) {
        max-width: 1100px;
      }

      &__title {
        max-width: 460px;
      }
    }

    .anticon-form {
      svg {
        font-size: 40px;
      }
    }
  }

  &__table {
    height: 100%;
    margin: auto;
    width: 100%;
    .ant-input {
      border: none !important;
    }
    .column__title {
      display: flex;
      .anticon-caret-down,
      .anticon-caret-up {
        padding-right: 20px;
        align-items: center;
      }
      .dropdown__status {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      &__wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        svg {
          width: 14px;
          height: 14px;
        }
      }
      &__sort {
        width: 20px;
        margin-top: -1px;
        display: flex;
        flex-direction: column;
        .anticon-caret-up,
        .anticon-caret-down {
          height: 9px;
        }
        .anticon-filter {
          display: flex;
          align-items: center;
          height: 100%;
          width: 20px;
        }
      }
    }

    .table-wrap .ant-table-tbody tr td {
      background-color: #222e35;
      padding: 0px 4px;
      cursor: pointer;

      .text {
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
      }
    }

    & .ant-table-tbody > tr.ant-table-row:hover > td {
      background: #222e35;
    }

    & .ant-table-row.red-column {
      td {
        background: linear-gradient(0deg, rgba(235, 87, 87, 0.3), rgba(235, 87, 87, 0.3)), rgba(100, 100, 100, 0.2);
      }
    }

    & .ant-table-row.delete-row {
      td {
        cursor: not-allowed !important;
      }
    }

    & .ant-table-tbody > tr.ant-table-row.red-column:hover > td {
      background: linear-gradient(0deg, rgba(235, 87, 87, 0.3), rgba(235, 87, 87, 0.3)), rgba(100, 100, 100, 0.2) !important;
    }

    .table-wrap .ant-table-tbody {
      .planType__text {
        padding-left: 20px;
      }

      .ant-table-cell:nth-child(4),
      .ant-table-cell:nth-child(5) {
        padding-right: 16px;
      }
    }

    max-width: 1180px;
    margin: auto;
    @media only screen and (max-width: 1225px) {
      overflow-x: scroll;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 5px !important;
        height: 10px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey;
        border-radius: 7px;
      }

      &::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 10px;
      }
    }

    .table-wrap {
      min-width: 1180px;
    }

    height: 100%;
    margin: auto;
    width: 100%;

    th.ant-table-cell {
      span {
        color: #49abb1;
      }
    }

    .ant-table-cell {
      position: relative;

      .planType__title {
        padding-left: 28px;
      }

      .status {
        justify-content: flex-start;
      }
    }

    .ant-table-thead .ant-table-cell {
      padding: 16px 0;
    }
  }

  .high-light {
    .btn-action {
      background-color: #5f3a3f;
    }
  }

  .btn-action {
    cursor: pointer;
    width: 32px;
    height: 32px;
    background-color: #222e35;
    box-shadow: 0px 6px 16px rgba(41, 43, 50, 0.12);
    display: flex;
    align-items: center;

    span {
      width: 40px;
      display: flex;
      justify-content: center;
    }

    &:hover {
      background: linear-gradient(0deg, rgba(6, 21, 30, 0.9), rgba(6, 21, 30, 0.9));
    }
  }

  &__pagination {
    margin-top: 32px;
  }
}
