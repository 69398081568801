.wrap-radio {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 80px;
  margin-top: 32px;
}

.plan-form {
  margin: 0 auto;
  width: 1300px;
}
.username__selection {
  .ant-select-selection-item {
    padding-right: 18px !important;
    margin-top: -9px !important;
    display: block;
    text-overflow: ellipsis;
  }

  .ant-select-disabled .ant-select-selector .ant-select-selection-item {
    display: inline-block !important;
    margin-top: 9px !important;
  }
}
.form-session {
  background: linear-gradient(0deg, rgba(126, 126, 126, 0.25), rgba(126, 126, 126, 0.25)), rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 40px rgba(24, 35, 43, 0.1);
  backdrop-filter: blur(600px);
  padding: 56px 40px;
  margin-bottom: 48px;
  .building_selection,
  .user_selection,
  .facility_selection,
  .building_item_selection {
    .ant-select-selection-item {
      padding-right: 18px !important;
      margin-top: -9px !important;
      display: block;
      text-overflow: ellipsis;
    }
    .ant-select-disabled .ant-select-selector .ant-select-selection-item {
      display: inline-block !important;
      margin-top: 9px !important;
    }
    .ant-select-clear {
      margin-right: 24px;
      .anticon-close-circle {
        font-size: 16px;
        margin-top: -2px;
      }
    }
  }
  h1 {
    font-size: 25px;
  }

  .plan-note {
    margin-top: 10px;

    &.period {
      @media only screen and (max-width: 768px) {
        margin-top: 16px;
      }
    }
  }

  .plan-action-btn {
    margin-top: 10px;
  }

  .ant-radio-wrapper {
    span {
      color: var(--color-white);
    }
    span:nth-child(2) {
    }
  }

  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
    background: #247e84;
  }

  .ant-picker-suffix {
    font-size: 19px;
  }

  .period-radio-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    label {
      display: flex;
      margin-top: 5px;
      .required-label {
        height: 24px;
      }
    }

    .ant-form-item {
      width: auto;
      label.ant-radio-wrapper {
        margin-top: 0;
      }
      .ant-radio-group {
        display: flex;
      }
    }
  }

  .form-title {
    text-align: center;
    color: var(--second-color);
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 16px;

    h1 {
      padding: 0 !important;
    }
  }
}
.ant-picker-header-view button:not(:first-child) {
  margin-left: 0px !important;
}
.ant-picker-date-panel .ant-picker-body,
.ant-picker-header,
.ant-picker-footer {
  border: 1px solid #247e84 !important;
  color: white;
  background-color: #242e34;

  .ant-picker-header-view {
    display: flex;
    justify-content: center;
  }

  .ant-picker-header-super-prev-btn,
  .ant-picker-header-prev-btn,
  .ant-picker-header-next-btn,
  .ant-picker-header-super-next-btn {
    color: white;
  }
}

.ant-form-item-control-input-content {
  input {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }
}

.ant-form-vertical .ant-form-item-label {
  font-weight: 500;
}

.group-checklist {
  &__endline {
    margin: 10px 0;
    border: 1px solid #4f4f4f;
  }
}

.wrap-reports {
  display: flex;
  flex-direction: column;
  gap: 37px;

  &__button {
    display: flex;
    gap: 12px;
  }

  &__radio {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__item {
    text-align: center;
    flex: 1;
  }
}

.ant-picker-dropdown {
  .ant-picker-time-panel {
    padding-top: 4px;
    border: 1px solid #247e84 !important;
    color: white;
    background-color: #242e34;

    .ant-picker-content {
      .ant-picker-time-panel-column {
        .ant-picker-time-panel-cell-inner {
          &:hover {
            background-color: #242e34;
          }
        }

        li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
          background-color: #247e84;
        }
      }
    }
  }

  .ant-picker-footer {
    li.ant-picker-now {
      .ant-picker-now-btn {
        color: #247e84;
      }
    }
  }
}

.deleted-selection-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  &__content {
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 5px;
  }
}
